<template>
    <div class="wrapper">
        <table class="custom-table">
            <tbody>
                <!-- 商砼产能 -->
                <tr>
                    <td rowspan="5" class="custom-th classify custom-th-odd border6">
                        商砼产能
                    </td>
                    <td class="custom-th" style="width:2rem;">
                        生产线数量（条）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.number"
                            @blur="computConcrete('number_free', data.concrete.number)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.number)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（条）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.number_use"
                            @blur="computConcrete('number_free', data.concrete.number_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.number_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（条）
                    </td>
                    <td :class="{'red': parseFloat(data.concrete.number_free)<0}">
                        {{ data.concrete.number_free }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        生产线能力（万方）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.ability"
                            @blur="computConcrete('ability_free', data.concrete.ability)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.ability)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（万方）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.ability_use"
                            @blur="computConcrete('ability_free', data.concrete.ability_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.ability_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万方）
                    </td>
                    <td :class="{'red': parseFloat(data.concrete.ability_free)<0}">
                        {{ data.concrete.ability_free }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        生产线价值（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.value"
                            @blur="computConcrete('value_free', data.concrete.value)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.value)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.concrete.value_use"
                            @blur="computConcrete('value_free', data.concrete.value_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.concrete.value_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.concrete.value_free)<0}">
                        {{ data.concrete.value_free }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        运行生产线占比(%)
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.concrete.number_ratio)<0}">
                        {{ data.concrete.number_ratio }}
                    </td>
                </tr>
                <tr class="border6">
                    <td colspan="2" class="custom-th">
                        总产能利用率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.concrete.ability_ratio)<0}">
                        {{ data.concrete.ability_ratio }}
                    </td>
                </tr>
                <!-- 砂浆产能 -->
                <tr>
                    <td rowspan="5" class="custom-th classify custom-th-even border6">
                        砂浆产能
                    </td>
                    <td class="custom-th">
                        生产线数量（条）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.number"
                            @blur="computMortar('number_free', data.mortar.number)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.number)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（条）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.number_use"
                            @blur="computMortar('number_free', data.mortar.number_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.number_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（条）
                    </td>
                    <td :class="{'red': parseFloat(data.mortar.number_free)<0}">
                        {{ data.mortar.number_free }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        生产线能力（万吨）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.ability"
                            @blur="computMortar('ability_free', data.mortar.ability)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.ability)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（万吨）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.ability_use"
                            @blur="computMortar('ability_free', data.mortar.ability_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.ability_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万吨）
                    </td>
                    <td :class="{'red': parseFloat(data.mortar.ability_free)<0}">
                        {{ data.mortar.ability_free }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        生产线价值（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.value"
                            @blur="computMortar('value_free', data.mortar.value)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.value)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        运行（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.mortar.value_use"
                            @blur="computMortar('value_free', data.mortar.value_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.mortar.value_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.mortar.value_free)<0}">
                        {{ data.mortar.value_free }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        运行生产线占比(%)
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.mortar.number_ratio)<0}">
                        {{ data.mortar.number_ratio }}
                    </td>
                </tr>
                <tr class="border6">
                    <td colspan="2" class="custom-th">
                        总产能利用率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.mortar.ability_ratio)<0}">
                        {{ data.mortar.ability_ratio }}
                    </td>
                </tr>
                <!-- 劳动生产率 -->
                <tr>
                    <td rowspan="10" class="custom-th custom-th-odd classify border6">
                        劳动生产率
                    </td>
                    <td colspan="2" class="custom-th">
                        人员（人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.total)<0}">
                        {{ data.person.total }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="6"></td>
                    <td rowspan="2" class="custom-th">
                        运输车司机（人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.driver_total)<0}">
                        {{ data.person.driver_total }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        商混（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.driver_concrete"
                            @blur="computPerson('driver_total', data.person.driver_concrete)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.driver_concrete)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        砂浆（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.driver_mortar"
                            @blur="computPerson('driver_total', data.person.driver_mortar)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.driver_mortar)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="custom-th">
                        销售人员（人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.sale_total)<0}">
                        {{ data.person.sale_total }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        商混（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.sale_concrete"
                            @blur="computPerson('sale_total', data.person.sale_concrete)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.sale_concrete)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        砂浆（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.sale_mortar"
                            @blur="computPerson('sale_total', data.person.sale_mortar)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.sale_mortar)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="custom-th">
                        管理及其他（人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.other_total)<0}">
                        {{ data.person.other_total }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        商混（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.other_concrete"
                            @blur="computPerson('other_total', data.person.other_concrete)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.other_concrete)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        砂浆（人）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.person.other_mortar"
                            @blur="computPerson('other_total', data.person.other_mortar)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.person.other_mortar)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        商砼人均运量（万方/人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.driver_average)<0}">
                        {{ data.person.driver_average }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        商砼人均销量（万方/人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.sale_average)<0}">
                        {{ data.person.sale_average }}
                    </td>
                </tr>
                <tr class="border6">
                    <td colspan="2" class="custom-th">
                        商砼人均产值（万元/人）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.person.value_average)<0}">
                        {{ data.person.value_average }}
                    </td>
                </tr>
                <!-- 罐车使用率 -->
                <tr>
                    <td rowspan="14" class="custom-th custom-th-even classify border6">
                        罐车使用率
                    </td>
                    <td rowspan="2" colspan="2" class="custom-th">
                        罐车数量（辆）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.number"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.number)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（辆）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.number_use"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.number_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（辆）
                    </td>
                    <td :class="{'red': parseFloat(data.tanker.number_free)<0}">
                        {{ data.tanker.number_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        运输能力（万方）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.ability"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.ability)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万方）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.ability_use"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.ability_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万方）
                    </td>
                    <td :class="{'red': parseFloat(data.tanker.ability_free)<0}">
                        {{ data.tanker.ability_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        罐车价值（万元）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.value"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.value)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.value_use"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.value_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.tanker.value_free)<0}">
                        {{ data.tanker.value_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        单车运输能力（方/辆*次）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.tanker.ability_single)<0}">
                        {{ data.tanker.ability_single }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（方/辆*次）
                    </td>
                    <td :class="{'red': parseFloat(data.tanker.ability_use_single)<0}">
                        {{ data.tanker.ability_use_single }}
                    </td>
                    <td class="custom-th">
                        闲置（方/辆*次）
                    </td>
                    <td :class="{'red': parseFloat(data.tanker.ability_free_single)<0}">
                        {{ data.tanker.ability_free_single }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        实际运输量（万方）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.volume_total"
                            @blur="computTanker"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.volume_total)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        罐车参运率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.tanker.join_rate)<0}">
                        {{ data.tanker.join_rate }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        罐车使用率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.tanker.user_rate)<0}">
                        {{ data.tanker.user_rate }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        运输里程（百公里）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.transport_mileage"
                            @blur="computTanker(data.tanker.transport_mileage)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.transport_mileage)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        运输次数（趟）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.tanker.transport_number"
                            @blur="computTanker(data.tanker.transport_number)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.tanker.transport_number)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr class="border6">
                    <td colspan="2" class="custom-th">
                        参运单车运输方量（方/辆*次）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.tanker.ability_single_use)<0}">
                        {{ data.tanker.ability_single_use }}
                    </td>
                </tr>
                <!-- 泵车使用率 -->
                <tr>
                    <td rowspan="11" class="custom-th custom-th-odd classify border6">
                        泵车使用率
                    </td>
                    <td rowspan="2" colspan="2" class="custom-th">
                        泵车数量（辆）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.number"
                            @blur="computPumpTruck(data.pumpTruck.number)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.number)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（辆）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.number_use"
                            @blur="computPumpTruck(data.pumpTruck.number_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.number_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（辆）
                    </td>
                    <td :class="{'red': parseFloat(data.pumpTruck.number_free)<0}">
                        {{ data.pumpTruck.number_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        运输能力（万方）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.ability"
                            @blur="computPumpTruck(data.pumpTruck.ability)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.ability)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万方）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.ability_use"
                            @blur="computPumpTruck(data.pumpTruck.ability_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.ability_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万方）
                    </td>
                    <td :class="{'red': parseFloat(data.pumpTruck.ability_free)<0}">
                        {{ data.pumpTruck.ability_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        泵车价值（万元）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.value"
                            @blur="computPumpTruck(data.pumpTruck.value)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.value)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.value_use"
                            @blur="computPumpTruck(data.pumpTruck.value_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.value_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.pumpTruck.value_free)<0}">
                        {{ data.pumpTruck.value_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        单车运输能力（方/辆*次）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.pumpTruck.ability_single)<0}">
                        {{ data.pumpTruck.ability_single }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（方/辆*次）
                    </td>
                    <td :class="{'red': parseFloat(data.pumpTruck.ability_use_single)<0}">
                        {{ data.pumpTruck.ability_use_single }}
                    </td>
                    <td class="custom-th">
                        闲置（方/辆*次）
                    </td>
                    <td :class="{'red': parseFloat(data.pumpTruck.ability_free_single)<0}">
                        {{ data.pumpTruck.ability_free_single }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        实际运输量（万方）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.pumpTruck.volume_total"
                            @blur="computPumpTruck(data.pumpTruck.volume_total)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.pumpTruck.volume_total)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        泵车参运率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.pumpTruck.join_rate)<0}">
                        {{ data.pumpTruck.join_rate }}
                    </td>
                </tr>
                <tr class="border6">
                    <td colspan="2" class="custom-th">
                        泵车使用率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.pumpTruck.user_rate)<0}">
                        {{ data.pumpTruck.user_rate }}
                    </td>
                </tr>
                <!-- 铲车使用率 -->
                <tr>
                    <td rowspan="12" class="custom-th custom-th-even classify">
                        铲车使用率
                    </td>
                    <td rowspan="2" colspan="2" class="custom-th">
                        铲车数量（辆）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.number"
                            @blur="computForkLift(data.forkLift.number)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.number)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（辆）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.number_use"
                            @blur="computForkLift(data.forkLift.number_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.number_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（辆）
                    </td>
                    <td :class="{'red': parseFloat(data.forkLift.number_free)<0}">
                        {{ data.forkLift.number_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        运输能力（万吨）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.ability"
                            @blur="computForkLift(data.forkLift.ability)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.ability)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万吨）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.ability_use"
                            @blur="computForkLift(data.forkLift.ability_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.ability_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万吨）
                    </td>
                    <td :class="{'red': parseFloat(data.forkLift.ability_free)<0}">
                        {{ data.forkLift.ability_free }}
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="2" class="custom-th">
                        铲车价值（万元）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.value"
                            @blur="computForkLift(data.forkLift.value)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.value)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td class="custom-th">
                        参运（万元）
                    </td>
                    <td>
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.value_use"
                            @blur="computForkLift(data.forkLift.value_use)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.value_use)<0}"
                        ></el-input>
                    </td>
                    <td class="custom-th">
                        闲置（万元）
                    </td>
                    <td :class="{'red': parseFloat(data.forkLift.value_free)<0}">
                        {{ data.forkLift.value_free }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        实际运输量（万吨）
                    </td>
                    <td colspan="4">
                        <el-input
                            :disabled="type=='approval'"
                            v-model="data.forkLift.volume_total"
                            @blur="computForkLift(data.forkLift.volume_total)"
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :class="{'red': parseFloat(data.forkLift.volume_total)<0}"
                        ></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        铲车参运率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.forkLift.join_rate)<0}">
                        {{ data.forkLift.join_rate }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        铲车使用率（%）
                    </td>
                    <td colspan="4" :class="{'red': parseFloat(data.forkLift.user_rate)<0}">
                        {{ data.forkLift.user_rate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: {
                concrete: {},
                forkLift: {},
                mortar: {},
                person: {},
                pumpTruck: {},
                tanker: {},
            },
            oldData: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取商砼上个月的数据
        getConcretePreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/concrete/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取砂浆上个月的数据
        getMortarPreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/mortar/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取劳动生产率上个月的数据
        getPersonPreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/person/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取罐车上个月数据
        getTankerPreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/tanker/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取泵车上个月数据
        getPumpTruckPreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/pumptruck/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取铲车上个月数据
        getForkliftPreMonth() {
            return this.$axios.get('/interfaceApi/report/assetuse/forklift/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取-资产使用
        getAssetuse() {
            this.$axios
                .get('/interfaceApi/report/assetuse/' + this.reportMonth.report_id)
                // eslint-disable-next-line complexity
                .then(async res => {
                    if (res) {
                        this.data = res;
                        this.oldData = JSON.parse(JSON.stringify(res));
                        if (this.type !== 'approval') {
                            const instanceRes = await this.getInstance();
                            if (instanceRes) {
                                if (instanceRes.report_state === 3) {
                                // 商砼初始化上个月的数据
                                    // eslint-disable-next-line max-depth
                                    if ( this.data.concrete.rac_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const concreteRes = await this.getConcretePreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (concreteRes) {
                                            // eslint-disable-next-line max-depth
                                                if (concreteRes.rac_id) {
                                                    res.concrete.number = concreteRes.number;
                                                    res.concrete.ability = concreteRes.ability;
                                                }
                                            }
                                        }
                                    }
                                    // 砂浆初始化上个月的数据
                                    // eslint-disable-next-line max-depth
                                    if (this.data.mortar.ram_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const mortarRes = await this.getMortarPreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (mortarRes) {
                                            // eslint-disable-next-line max-depth
                                                if (mortarRes.ram_id) {
                                                    res.mortar.number = mortarRes.number;
                                                    res.mortar.ability = mortarRes.ability;
                                                }
                                            }
                                        }
                                    }
                                    // 劳动生产率初始化上个月数据
                                    // eslint-disable-next-line max-depth
                                    if (this.data.person.rap_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const personRes = await this.getPersonPreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (personRes) {
                                            // eslint-disable-next-line max-depth
                                                if (personRes.rap_id) {
                                                    delete personRes.rap_id;
                                                    delete personRes.report_id;
                                                    this.data.person = personRes;
                                                    this.data.person.rap_id = null;
                                                    this.data.person.report_id = this.reportMonth.report_id;
                                                    this.data.person.driver_average = null;
                                                    this.data.person.sale_average = null;
                                                    this.data.person.value_average = null;
                                                    this.data.person.total = null;
                                                }
                                            }
                                        }
                                    }
                                    // 罐车初始化上个月的数据
                                    // eslint-disable-next-line max-depth
                                    if (this.data.tanker.rat_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const tankerRes = await this.getTankerPreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (tankerRes) {
                                            // eslint-disable-next-line max-depth
                                                if (tankerRes.rat_id) {
                                                    this.data.tanker.number = tankerRes.number;
                                                    this.data.tanker.ability = tankerRes.ability;
                                                }
                                            }
                                        }
                                    }
                                    // 泵车初始化上个月的数据
                                    // eslint-disable-next-line max-depth
                                    if (this.data.pumpTruck.rap_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const pumpTruckRes = await this.getPumpTruckPreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (pumpTruckRes) {
                                            // eslint-disable-next-line max-depth
                                                if (pumpTruckRes.rap_id) {
                                                    this.data.pumpTruck.number = pumpTruckRes.number;
                                                    this.data.pumpTruck.ability = pumpTruckRes.ability;
                                                }
                                            }
                                        }
                                    }
                                    // 铲车初始化上个月的数据
                                    // eslint-disable-next-line max-depth
                                    if (this.data.forkLift.raf_id === null) {
                                    // eslint-disable-next-line max-depth
                                        if (this.reportMonth.report_month > 1) {
                                            const forkLiftRes = await this.getForkliftPreMonth();
                                            // eslint-disable-next-line max-depth
                                            if (forkLiftRes) {
                                            // eslint-disable-next-line max-depth
                                                if (forkLiftRes.raf_id) {
                                                    this.data.forkLift.number = forkLiftRes.number;
                                                    this.data.forkLift.ability = forkLiftRes.ability;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }


                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-资产使用
        save() {
            const json = this.filterData();
            if (!json.isExistMinus) {
                this.$axios
                    .post('/interfaceApi/report/assetuse/save', json.parseData)
                    .then(res => {
                        if (res) {
                            if (res.concrete) {
                                this.data.concrete = res.concrete;
                            }
                            if (res.forkLift) {
                                this.data.forkLift = res.forkLift;
                            }
                            if (res.mortar) {
                                this.data.mortar = res.mortar;
                            }
                            if (res.person) {
                                this.data.person = res.person;
                            }
                            if (res.pumpTruck) {
                                this.data.pumpTruck = res.pumpTruck;
                            }
                            if (res.tanker) {
                                this.data.tanker = res.tanker;
                            }
                            this.oldData = JSON.parse(JSON.stringify(this.data));
                            this.$message.success('保存成功');
                            this.$emit('save-end', 'assetuse', 'success');
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                        this.$emit('save-end', 'sales', 'error');
                    });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }

        },
        // 过滤数据 null的转换成0,并判断是否存在负数
        // eslint-disable-next-line complexity
        filterData() {
            const newData = {};
            const concreteB = util.Compare(this.oldData.concrete, this.data.concrete);
            if (!concreteB) {
                newData.concrete = this.data.concrete;
            }
            const forkLiftB = util.Compare(this.oldData.forkLift, this.data.forkLift);
            if (!forkLiftB) {
                newData.forkLift = this.data.forkLift;
            }
            const mortarB = util.Compare(this.oldData.mortar, this.data.mortar);
            if (!mortarB) {
                newData.mortar = this.data.mortar;
            }
            const personB = util.Compare(this.oldData.person, this.data.person);
            if (!personB) {
                newData.person = this.data.person;
            }
            const pumpTruckB = util.Compare(this.oldData.pumpTruck, this.data.pumpTruck);
            if (!pumpTruckB) {
                newData.pumpTruck = this.data.pumpTruck;
            }
            const tankerB = util.Compare(this.oldData.tanker, this.data.tanker);
            if (!tankerB) {
                newData.tanker = this.data.tanker;
            }
            if (newData.concrete) {
                newData.concrete.number = parseInt(newData.concrete.number, 10);
                newData.concrete.number_use = parseInt(newData.concrete.number_use, 10);
                newData.concrete.number_free = parseInt(newData.concrete.number_free, 10);
            }
            if (newData.mortar) {
                newData.mortar.number = parseInt(newData.mortar.number, 10);
                newData.mortar.number_use = parseInt(newData.mortar.number_use, 10);
                newData.mortar.number_free = parseInt(newData.mortar.number_free, 10);
            }
            if (newData.forkLift) {
                newData.forkLift.number = parseInt(newData.forkLift.number, 10);
                newData.forkLift.number_use = parseInt(newData.forkLift.number_use, 10);
                newData.forkLift.number_free = parseInt(newData.forkLift.number_free, 10);
            }
            if (newData.person) {
                newData.person.total = parseInt(newData.person.total, 10);
                newData.person.driver_concrete = parseInt(newData.person.driver_concrete, 10);
                newData.person.driver_mortar = parseInt(newData.person.driver_mortar, 10);
                newData.person.sale_total = parseInt(newData.person.sale_total, 10);
                newData.person.sale_concrete = parseInt(newData.person.sale_concrete, 10);
                newData.person.sale_mortar = parseInt(newData.person.sale_mortar, 10);
                newData.person.other_total = parseInt(newData.person.other_total, 10);
                newData.person.other_concrete = parseInt(newData.person.other_concrete, 10);
                newData.person.other_mortar = parseInt(newData.person.other_mortar, 10);
            }
            if (newData.pumpTruck) {
                newData.pumpTruck.number = parseInt(newData.pumpTruck.number, 10);
                newData.pumpTruck.number_use = parseInt(newData.pumpTruck.number_use, 10);
                newData.pumpTruck.number_free = parseInt(newData.pumpTruck.number_free, 10);
            }
            if (newData.tanker) {
                newData.tanker.number = parseInt(newData.tanker.number, 10);
                newData.tanker.number_use = parseInt(newData.tanker.number_use, 10);
                newData.tanker.number_free = parseInt(newData.tanker.number_free, 10);
            }
            const json = { parseData: newData, isExistMinus: false };
            let concreteFalse = false;
            if (newData.concrete) {
                for (const key in newData.concrete) {
                    let notParse = false;
                    if (key === 'rac_id' || key === 'report_id' || key === 'number_ratio' || key === 'ability_ratio') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.concrete[key]) {
                            newData.concrete[key] = 0;
                        } else {
                            newData.concrete[key] = parseFloat(newData.concrete[key]);
                        }
                        if (newData.concrete[key] < 0) {
                            concreteFalse = true;
                        }
                    }
                }
            }
            let mortarFalse = false;
            if (newData.mortar) {
                for (const key in newData.mortar) {
                    let notParse = false;
                    if (key === 'ram_id' || key === 'report_id' || key === 'number_ratio' || key === 'ability_ratio') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.mortar[key]) {
                            newData.mortar[key] = 0;
                        } else {
                            newData.mortar[key] = parseFloat(newData.mortar[key]);
                        }
                        if (newData.mortar[key] < 0) {
                            mortarFalse = true;
                        }
                    }
                }
            }
            let forkLiftFalse = false;
            if (newData.forkLift) {
                for (const key in newData.forkLift) {
                    let notParse = false;
                    if (key === 'raf_id' || key === 'report_id' || key === 'join_rate' || key === 'user_rate') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.forkLift[key]) {
                            newData.forkLift[key] = 0;
                        } else {
                            newData.forkLift[key] = parseFloat(newData.forkLift[key]);
                        }
                        if (newData.forkLift[key] < 0) {
                            forkLiftFalse = true;
                        }
                    }
                }
            }
            let personFalse = false;
            if (newData.person) {
                for (const key in newData.person) {
                    let notParse = false;
                    if (key === 'rap_id' || key === 'report_id' || key === 'driver_average' || key === 'sale_average' || key === 'value_average') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.person[key]) {
                            newData.person[key] = 0;
                        } else {
                            newData.person[key] = parseFloat(newData.person[key]);
                        }
                        if (newData.person[key] < 0) {
                            personFalse = true;
                        }
                    }
                }
            }
            let pumpTruckFalse = false;
            if (newData.pumpTruck) {
                for (const key in newData.pumpTruck) {
                    let notParse = false;
                    if (key === 'rap_id' || key === 'report_id' || key === 'join_rate' || key === 'user_rate' || key === 'ability_single_use') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.pumpTruck[key]) {
                            newData.pumpTruck[key] = 0;
                        } else {
                            newData.pumpTruck[key] = parseFloat(newData.pumpTruck[key]);
                        }
                        if (newData.pumpTruck[key] < 0) {
                            pumpTruckFalse = true;
                        }
                    }
                }
            }
            let tankerFalse = false;
            if (newData.tanker) {
                for (const key in newData.tanker) {
                    let notParse = false;
                    if (key === 'rat_id' || key === 'report_id' || key === 'join_rate' || key === 'user_rate' || key === 'ability_single_use') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.tanker[key]) {
                            newData.tanker[key] = 0;
                        } else {
                            newData.tanker[key] = parseFloat(newData.tanker[key]);
                        }
                        if (newData.tanker[key] < 0) {
                            tankerFalse = true;
                        }
                    }
                }
            }
            json.parseData = newData;
            if (concreteFalse || mortarFalse || forkLiftFalse || personFalse || pumpTruckFalse || tankerFalse) {
                json.isExistMinus = true;
            }
            return json;
        },
        // 混凝土本月销量
        getConcreteMonthSales() {
            return this.$axios.get('/interfaceApi/report/sales/concretesales/month/' + this.reportMonth.report_id);
        },
        // 计算-商砼产能
        async computConcrete(type, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (type === 'number_free') {
                let number = this.data.concrete.number;
                let number_use = this.data.concrete.number_use;
                if (!number) {
                    number = 0;
                }
                if (!number_use) {
                    number_use = 0;
                }
                // eslint-disable-next-line radix
                this.data.concrete.number_free = util.toFixed6(parseInt(number) - parseInt(number_use));
                if (parseFloat(number) && parseFloat(number_use)) {
                    // eslint-disable-next-line radix
                    this.data.concrete.number_ratio = util.toFixed6(parseInt(number_use) / parseInt(number) * 100);
                } else {
                    this.data.concrete.number_ratio = 0;
                }
            }
            if (type === 'ability_free') {
                let ability = this.data.concrete.ability;
                let ability_use = this.data.concrete.ability_use;
                if (!ability) {
                    ability = 0;
                }
                if (!ability_use) {
                    ability_use = 0;
                }
                this.data.concrete.ability_free = util.toFixed6(parseFloat(ability) - parseFloat(ability_use));
                // 总产能利用率（%）
                const res = await this.getConcreteMonthSales();
                if (res !== 0 && parseFloat(ability) !== 0) {
                    this.data.concrete.ability_ratio = util.toFixed6(res / parseFloat(ability) * 100);
                } else {
                    this.data.concrete.ability_ratio = 0;
                }
            }
            if (type === 'value_free') {
                let value = this.data.concrete.value;
                let value_use = this.data.concrete.value_use;
                if (!value) {
                    value = 0;
                }
                if (!value_use) {
                    value_use = 0;
                }
                this.data.concrete.value_free = util.toFixed6(parseFloat(value) - parseFloat(value_use));
            }
        },
        // 砂浆本月销量
        getMortarMonthSales() {
            return this.$axios.get('/interfaceApi/report/sales/mortarsales/month/' + this.reportMonth.report_id);
        },
        // 计算砂浆
        async computMortar(type, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (type === 'number_free') {
                let number = this.data.mortar.number;
                let number_use = this.data.mortar.number_use;
                if (!number) {
                    number = 0;
                }
                if (!number_use) {
                    number_use = 0;
                }
                // eslint-disable-next-line radix
                this.data.mortar.number_free = util.toFixed6(parseInt(number) - parseInt(number_use));
                if (parseFloat(number) && parseFloat(number_use)) {
                    // eslint-disable-next-line radix
                    this.data.mortar.number_ratio = util.toFixed6(parseInt(number_use) / parseInt(number) * 100);
                } else {
                    this.data.mortar.number_ratio = 0;
                }
            }
            if (type === 'ability_free') {
                let ability = this.data.mortar.ability;
                let ability_use = this.data.mortar.ability_use;
                if (!ability) {
                    ability = 0;
                }
                if (!ability_use) {
                    ability_use = 0;
                }
                this.data.mortar.ability_free = util.toFixed6(parseFloat(ability) - parseFloat(ability_use));
                // 总产能利用率（%）
                if (parseFloat(ability) !== 0) {
                    const res = await this.getMortarMonthSales();
                    if (res !== 0) {
                        this.data.mortar.ability_ratio = util.toFixed6(res / parseFloat(ability) * 100);
                    } else {
                        this.data.mortar.ability_ratio = 0;
                    }
                } else {
                    this.data.mortar.ability_ratio = 0;
                }
            }
            if (type === 'value_free') {
                let value = this.data.mortar.value;
                let value_use = this.data.mortar.value_use;
                if (!value) {
                    value = 0;
                }
                if (!value_use) {
                    value_use = 0;
                }
                this.data.mortar.value_free = util.toFixed6(parseFloat(value) - parseFloat(value_use));
            }
        },
        // 本月 混凝土+砂浆 销量和
        getSalesTotal() {
            return this.$axios.get('/interfaceApi/report/sales/conmorsales/month/' + this.reportMonth.report_id);
        },
        // 获取本月混凝土销量
        getConcretesales() {
            return this.$axios.get('/interfaceApi/report/sales/concretesales/month/' + this.reportMonth.report_id);
        },
        // 本月 总销售额
        getMonTotalsales() {
            return this.$axios.get('/interfaceApi/report/sales/totalsales/month/' + this.reportMonth.report_id);
        },
        // 计算劳动生产率
        // eslint-disable-next-line complexity
        async computPerson(type, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            this.data.person.total = 0;
            if (type === 'driver_total') {
                this.data.person.driver_total = 0;
                let driver_concrete = this.data.person.driver_concrete;
                let driver_mortar = this.data.person.driver_mortar;
                if (!driver_concrete) {
                    driver_concrete = 0;
                }
                if (!driver_mortar) {
                    driver_mortar = 0;
                }
                // eslint-disable-next-line radix
                this.data.person.driver_total = util.toFixed6(parseInt(this.data.person.driver_total) + parseInt(driver_concrete));
                // eslint-disable-next-line radix
                this.data.person.driver_total = util.toFixed6(parseInt(this.data.person.driver_total) + parseInt(driver_mortar));
            }
            if (type === 'sale_total') {
                this.data.person.sale_total = 0;
                let sale_concrete = this.data.person.sale_concrete;
                let sale_mortar = this.data.person.sale_mortar;
                if (!sale_concrete) {
                    sale_concrete = 0;
                }
                if (!sale_mortar) {
                    sale_mortar = 0;
                }
                // eslint-disable-next-line radix
                this.data.person.sale_total = util.toFixed6(parseInt(this.data.person.sale_total) + parseInt(sale_concrete));
                // eslint-disable-next-line radix
                this.data.person.sale_total = util.toFixed6(parseInt(this.data.person.sale_total) + parseInt(sale_mortar));
            }
            if (type === 'other_total') {
                this.data.person.other_total = 0;
                let other_concrete = this.data.person.other_concrete;
                let other_mortar = this.data.person.other_mortar;
                if (!other_concrete) {
                    other_concrete = 0;
                }
                if (!other_mortar) {
                    other_mortar = 0;
                }
                // eslint-disable-next-line radix
                this.data.person.other_total = util.toFixed6(parseInt(this.data.person.other_total) + parseInt(other_concrete));
                // eslint-disable-next-line radix
                this.data.person.other_total = util.toFixed6(parseInt(this.data.person.other_total) + parseInt(other_mortar));
            }
            this.data.person.total = this.data.person.driver_total + this.data.person.sale_total + this.data.person.other_total;
            const res = await this.getSalesTotal();
            if (res && parseFloat(this.data.person.sale_concrete)) {
                this.data.person.sale_average = util.toFixed6(res / parseFloat(this.data.person.sale_concrete));
            } else {
                this.data.person.sale_average = 0;
            }
            const res1 = await this.getMonTotalsales();
            const concrete_person = this.data.person.total - this.data.person.driver_total;
            if (res1 && parseFloat(concrete_person)) {
                this.data.person.value_average = util.toFixed6(res1 / parseFloat(concrete_person));
            } else {
                this.data.person.value_average = 0;
            }
            const resSales = await this.getConcretesales();
            if (resSales) {
                // 人均运量
                if (parseFloat(this.data.person.total) && this.data.person.driver_concrete) {
                    this.data.person.driver_average = util.toFixed6(resSales / parseFloat(this.data.person.driver_concrete));
                }
                if (parseFloat(this.data.person.total) === 0) {
                    this.data.person.driver_average = 0;
                }
            } else {
                this.data.person.driver_average = 0;
            }
        },
        // 计算-罐车使用率
        // eslint-disable-next-line complexity
        computTanker(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            // 罐车闲置
            let number = this.data.tanker.number;
            let number_use = this.data.tanker.number_use;
            if (!number) {
                number = 0;
            }
            if (!number_use) {
                number_use = 0;
            }
            // eslint-disable-next-line radix
            this.data.tanker.number_free = util.toFixed6(parseInt(number) - parseInt(number_use));
            if (parseFloat(number) && parseFloat(number_use)) {
                // eslint-disable-next-line radix
                this.data.tanker.join_rate = util.toFixed6(parseInt(number_use) / parseInt(number) * 100);
            } else {
                this.data.tanker.join_rate = 0;
            }
            // 运输能力闲置
            let ability = this.data.tanker.ability;
            let ability_use = this.data.tanker.ability_use;
            if (!ability) {
                ability = 0;
            }
            if (!ability_use) {
                ability_use = 0;
            }
            this.data.tanker.ability_free = util.toFixed6(parseFloat(ability) - parseFloat(ability_use));
            // 价值闲置
            let value = this.data.tanker.value;
            let value_use = this.data.tanker.value_use;
            if (!value) {
                value = 0;
            }
            if (!value_use) {
                value_use = 0;
            }
            this.data.tanker.value_free = util.toFixed6(parseFloat(value) - parseFloat(value_use));
            // 单车运输能力（方/辆*次）
            if (parseFloat(ability) && parseFloat(number)) {
                this.data.tanker.ability_single = util.toFixed6(parseFloat(ability) * 10000 / (parseFloat(number) * 150));
            } else {
                this.data.tanker.ability_single = 0;
            }
            // 单车参运（方/辆*次
            if (parseFloat(ability_use) && parseFloat(number_use)) {
                this.data.tanker.ability_use_single = util.toFixed6(parseFloat(ability_use) * 10000 / (parseFloat(number_use) * 150));
            } else {
                this.data.tanker.ability_use_single = 0;
            }
            // 单车闲置（方/辆*次）
            if (parseFloat(this.data.tanker.ability_free) && parseFloat(this.data.tanker.number_free)) {
                this.data.tanker.ability_free_single
                = util.toFixed6(parseFloat(this.data.tanker.ability_free) * 10000 / (parseFloat(this.data.tanker.number_free) * 150));
            } else {
                this.data.tanker.ability_free_single = 0;
            }
            // 罐车使用率 (%)
            if (parseFloat(this.data.tanker.volume_total) && parseFloat(ability)) {
                this.data.tanker.user_rate = util.toFixed6(parseFloat(this.data.tanker.volume_total) / parseFloat(ability) * 100);
            } else {
                this.data.tanker.user_rate = 0;
            }
            // 参运单车运输量
            if (parseFloat(this.data.tanker.volume_total) && parseFloat(this.data.tanker.transport_number)) {
                this.data.tanker.ability_single_use
                = util.toFixed6(parseFloat(this.data.tanker.volume_total) * 10000 / parseFloat(this.data.tanker.transport_number));
            } else {
                this.data.tanker.ability_single_use = 0;
            }
        },
        // 计算-泵车
        // eslint-disable-next-line complexity
        computPumpTruck(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            let number = this.data.pumpTruck.number;
            let number_use = this.data.pumpTruck.number_use;
            if (!number) {
                number = 0;
            }
            if (!number_use) {
                number_use = 0;
            }
            // eslint-disable-next-line radix
            this.data.pumpTruck.number_free = util.toFixed6(parseInt(number) - parseInt(number_use));
            if (parseFloat(number) && parseFloat(number_use)) {
                // eslint-disable-next-line radix
                this.data.pumpTruck.join_rate = util.toFixed6(parseInt(number_use) / parseInt(number) * 100);
            } else {
                this.data.pumpTruck.join_rate = 0;
            }
            let ability = this.data.pumpTruck.ability;
            let ability_use = this.data.pumpTruck.ability_use;
            if (!ability) {
                ability = 0;
            }
            if (!ability_use) {
                ability_use = 0;
            }
            this.data.pumpTruck.ability_free = util.toFixed6(parseFloat(ability) - parseFloat(ability_use));
            let value = this.data.pumpTruck.value;
            let value_use = this.data.pumpTruck.value_use;
            if (!value) {
                value = 0;
            }
            if (!value_use) {
                value_use = 0;
            }
            this.data.pumpTruck.value_free = util.toFixed6(parseFloat(value) - parseFloat(value_use));
            // 单车运输能力（方/辆*次）
            if (parseFloat(ability) && parseFloat(number)) {
                this.data.pumpTruck.ability_single = util.toFixed6(parseFloat(ability) * 10000 / (parseFloat(number) * 150));
            } else {
                this.data.pumpTruck.ability_single = 0;
            }
            // 单车参运（方/辆*次
            if (parseFloat(ability_use) && parseFloat(number_use)) {
                this.data.pumpTruck.ability_use_single = util.toFixed6(parseFloat(ability_use) * 10000 / (parseFloat(number_use) * 150));
            } else {
                this.data.pumpTruck.ability_use_single = 0;
            }
            // 单车闲置（方/辆*次）
            if (parseFloat(this.data.pumpTruck.ability_free) && parseFloat(this.data.pumpTruck.number_free)) {
                this.data.pumpTruck.ability_free_single
                = util.toFixed6(parseFloat(this.data.pumpTruck.ability_free) * 10000 / (parseFloat(this.data.pumpTruck.number_free) * 150));
            } else {
                this.data.pumpTruck.ability_free_single = 0;
            }
            // 泵车使用率 (%)
            if (parseFloat(this.data.pumpTruck.volume_total) && parseFloat(ability)) {
                this.data.pumpTruck.user_rate = util.toFixed6(parseFloat(this.data.pumpTruck.volume_total) / parseFloat(ability) * 100);
            } else {
                this.data.pumpTruck.user_rate = 0;
            }
        },
        // 计算-铲车
        computForkLift(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            let number = this.data.forkLift.number;
            let number_use = this.data.forkLift.number_use;
            if (!number) {
                number = 0;
            }
            if (!number_use) {
                number_use = 0;
            }
            // eslint-disable-next-line radix
            this.data.forkLift.number_free = util.toFixed6(parseInt(number) - parseInt(number_use));
            if (parseFloat(number) && parseFloat(number_use)) {
                // eslint-disable-next-line radix
                this.data.forkLift.join_rate = util.toFixed6(parseInt(number_use) / parseInt(number) * 100);
            } else {
                this.data.forkLift.join_rate = 0;
            }

            let ability = this.data.forkLift.ability;
            let ability_use = this.data.forkLift.ability_use;
            if (!ability) {
                ability = 0;
            }
            if (!ability_use) {
                ability_use = 0;
            }
            this.data.forkLift.ability_free = util.toFixed6(parseFloat(ability) - parseFloat(ability_use));

            let value = this.data.forkLift.value;
            let value_use = this.data.forkLift.value_use;
            if (!value) {
                value = 0;
            }
            if (!value_use) {
                value_use = 0;
            }
            this.data.forkLift.value_free = util.toFixed6(parseFloat(value) - parseFloat(value_use));

            // 单车运输能力（方/辆*次）
            if (parseFloat(ability) && parseFloat(number)) {
                this.data.forkLift.ability_single = util.toFixed6(parseFloat(ability) * 10000 / (parseFloat(number) * 150));
            } else {
                this.data.forkLift.ability_single = 0;
            }
            // 单车参运（方/辆*次
            if (parseFloat(ability_use) && parseFloat(number_use)) {
                this.data.forkLift.ability_use_single = util.toFixed6(parseFloat(ability_use) * 10000 / (parseFloat(number_use) * 150));
            } else {
                this.data.forkLift.ability_use_single = 0;
            }
            // 单车闲置（方/辆*次）
            if (parseFloat(this.data.forkLift.ability_free) && parseFloat(this.data.forkLift.number_free)) {
                this.data.forkLift.ability_free_single
                = util.toFixed6(parseFloat(this.data.forkLift.ability_free) * 10000 / (parseFloat(this.data.forkLift.number_free) * 150));
            } else {
                this.data.forkLift.ability_free_single = 0;
            }
            // 铲车使用率 (%)
            if (parseFloat(this.data.forkLift.volume_total) && parseFloat(ability)) {
                this.data.forkLift.user_rate = util.toFixed6(parseFloat(this.data.forkLift.volume_total) / parseFloat(ability) * 100);
            } else {
                this.data.forkLift.user_rate = 0;
            }
        },
    },
    created() {
        this.getAssetuse();
    },
    mounted() {},
};
</script>
<style lang="stylus">
</style>